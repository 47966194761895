<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="RegistrationMechanism" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <b-container>
          <h4>{{ $t("registration.title") }}</h4>
          <ul class="steps-ul">
            <li>
              {{ $t("registration.li1") }}
            </li>
            <li>{{ $t("registration.li2") }}</li>
            <li>{{ $t("registration.li3") }}</li>
            <li>{{ $t("registration.li4") }}</li>
            <li>{{ $t("registration.li5") }}</li>
            <li>
              {{ $t("registration.li6") }}
            </li>
          </ul>
        </b-container>
      </section>
      <section class="sec">
        <b-container>
          <a class="links-button"
           :href="'/application/terms'" 
            target="_blank"
          >
            <button-fill text="AdmissionRequirements" />
          </a>
          <a class="links-button"
            :href="`/application/admission`"
            target="_blank"
          >
            <button-fill text="admissionPlan" />
          </a>
          <a class="links-button"
            :href="`/application/transfer`"
            target="_blank"
          >
            <button-fill text="ConditionsForTransferringBetweenUniversities" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
  if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Registration mechanism`;
    document.querySelector('meta[name="title"]').setAttribute("content","Registration mechanism");
    document.querySelector('meta[name="description"]').setAttribute("content", "The first steps of registration is to enter the private university education portal through the password and the exam in the event that the student is a graduate of the current academic year, but if the student is a graduate of previous years, he must go to the counseling center with the identification documents, and endorsement of graduation from the preparatory school for the purpose of obtaining a number Exam and password.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Registration mechanism");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The first steps of registration is to enter the private university education portal through the password and the exam in the event that the student is a graduate of the current academic year, but if the student is a graduate of previous years, he must go to the counseling center with the identification documents, and endorsement of graduation from the preparatory school for the purpose of obtaining a number Exam and password.");
    }else{
    document.title = `جامعة النسور  - الية التسجيل`;
    document.querySelector('meta[name="title"]').setAttribute("content","الية التسجيل");
    document.querySelector('meta[name="description"]').setAttribute("content", "أولى خطوات التسجيل هي الدخول إلى بوابة التعليم الجامعي الأهلي من طريق الرقم السري والامتحاني في حال كان الطالب من خريجي العام الدراسي الحالي، أما إذا كان الطالب من خريجي السنوات السابقة فيتوجب عليه الذهاب إلى المركز الإرشادي مستصحباً المستمسكات الثبوتية، وتأييد تخرج من الإعدادية لغرض استحصال رقم امتحاني ورقم سري");
    document.querySelector('meta[property="og:title"]').setAttribute("content","الية التسجيل");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "أولى خطوات التسجيل هي الدخول إلى بوابة التعليم الجامعي الأهلي من طريق الرقم السري والامتحاني في حال كان الطالب من خريجي العام الدراسي الحالي، أما إذا كان الطالب من خريجي السنوات السابقة فيتوجب عليه الذهاب إلى المركز الإرشادي مستصحباً المستمسكات الثبوتية، وتأييد تخرج من الإعدادية لغرض استحصال رقم امتحاني ورقم سري");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
