<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="sus.sus1" />
    </header>
    <main>
      <section class="section-details">
        <b-container>
          <h4 class="mb-5">{{ $t("sus.sus1") }}</h4> 
          <p class="mb-5">
            {{ $t("sus.sus2") }}
          </p>
          <a                
            :href="`lib/pdfjs/web/files/susInves.pdf`"
            target="_blank"
          >
            <button-fill text="readmore1" />
          </a>
        </b-container>
      </section>
      <section class="section-d">
        <b-container>
          <a class="links-button"
            :href="'continuse-development'" 
            target="_blank"
          >
            <button-fill text="sustainable" />
          </a>
          <a class="links-button"
           :href="'Sustainable-procurement-procedures'" 
            target="_blank"
          >
            <button-fill text="sus.sus1" />
          </a>
          <a class="links-button"
            :href="`Sustainable-Meeting`"
            target="_blank"
          >
            <button-fill text="sus.sus29" />
          </a>
          <a class="links-button"
           :href="'Sustainability-Donations-Funding'" 
            target="_blank"
          >
            <button-fill text="sus.sus64" />
          </a>
          <a class="links-button"
           :href="'Sustainability-Researches'" 
            target="_blank"
          >
            <button-fill text="susResearches" />
          </a>
          
          <a class="links-button"
           :href="'Sustainability-Courses'" 
            target="_blank"
          >
            <button-fill text="susCourses" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      name: "",
      path: "lib/pdfjs/web/viewer.html",
      isMobile: false,
      host: window.location.host,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - The Sustainable Investment Policy of Al-Nisour University `;
    document.querySelector('meta[name="title"]').setAttribute("content","The Sustainable Investment Policy of Al-Nisour University ");
    document.querySelector('meta[name="description"]').setAttribute("content", "Al-Nisour University  is committed to applying sustainable investment that takes into account environmental and social standards, corporate governance standards or any other standards, based on certain moral or ethical values to generate long-term financial returns, and to create a positive impact on society, the environment or the economy as a whole at the same time. Sustainable investing in almost all asset classes including cash, stocks, bonds and even alternative investments.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","The Sustainable Investment Policy of Al-Nisour University ");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "Al-Nisour University  is committed to applying sustainable investment that takes into account environmental and social standards, corporate governance standards or any other standards, based on certain moral or ethical values to generate long-term financial returns, and to create a positive impact on society, the environment or the economy as a whole at the same time. Sustainable investing in almost all asset classes including cash, stocks, bonds and even alternative investments.");
    }else{
    document.title = `جامعة النسور  - سياسة الاستثمار المستدام لجامعة النسور`;
    document.querySelector('meta[name="title"]').setAttribute("content","سياسة الاستثمار المستدام لجامعة النسور");
    document.querySelector('meta[name="description"]').setAttribute("content", "تلتزم جامعة النسور بتطبيق الاستثمار المستدام الذي يراعي المعايير البيئية والاجتماعية ومعايير حوكمة المؤسسات أو أي معايير أخرى، وذلك بناء على قيم أخلاقية أو معنوية معينة لتوليد عائدات مالية طويلة المدى، وإحداث تأثير إيجابي في المجتمع أو البيئة أو الاقتصاد ككل في نفس الوقت, ويتم تطبيق الاستثمار المستدام في جميع فئات الأصول تقريب ا بما في ذلك");
    document.querySelector('meta[property="og:title"]').setAttribute("content","سياسة الاستثمار المستدام لجامعة النسور");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "تلتزم جامعة النسور بتطبيق الاستثمار المستدام الذي يراعي المعايير البيئية والاجتماعية ومعايير حوكمة المؤسسات أو أي معايير أخرى، وذلك بناء على قيم أخلاقية أو معنوية معينة لتوليد عائدات مالية طويلة المدى، وإحداث تأثير إيجابي في المجتمع أو البيئة أو الاقتصاد ككل في نفس الوقت, ويتم تطبيق الاستثمار المستدام في جميع فئات الأصول تقريب ا بما في ذلك");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}
</style>
