<template>
  <div class="faq">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="faq" />
    </header>
    <main>
      <b-container>
        <section class="section-faq" v-if="faqs.length !== 0">
          <div class="faq-container" v-for="(faq, index) in faqs" :key="faq.id">
            <h2
              @click="rotateArrow(index)"
              v-b-toggle="`collapse-${index + 1}`"
            >
              {{ faq.question }}
              <i class="fas fa-chevron-left"></i>
            </h2>
            <b-collapse :id="`collapse-${index + 1}`" class="mt-2">
              <b-card>
                <p class="card-text" :id="`faq-answer-${index}`"></p>
              </b-card>
            </b-collapse>
          </div>
        </section>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import Quill from "quill";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      faqs: [],
      isMobile: false,
    };
  },
  async mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Common Questions`;
    document.querySelector('meta[name="title"]').setAttribute("content","Common Questions");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Common Questions");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `جامعة النسور  - الاسئلة الشائعة`;
    document.querySelector('meta[name="title"]').setAttribute("content","الاسئلة الشائعة");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","الاسئلة الشائعة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    await this.getFaqs();
    let data = [];
    this.faqs.forEach((faq, index) => {
      data.push(JSON.parse(faq.answer));
      let article = document.createElement("article");
      let quill = new Quill(article, {});
      quill.enable(false);
      quill.setContents(data[index].ops);
      let answer = document.getElementById("faq-answer-" + index);
      answer.appendChild(article);
    });
    // data.push(JSON.parse(this.jobDetails.description));
    // console.log(data);
    // let des_article = document.createElement("article");
    // let des_quill = new Quill(des_article, {});
    // des_quill.enable(false);
    // des_quill.setContents(data[0].ops);
    // let des_discription = document.getElementById("text");
    // des_discription.appendChild(des_article);
  },
  methods: {
    rotateArrow(index) {
      let arrows = document.querySelectorAll(".fa-chevron-left");
      if (document.documentElement.lang == "ar") {
        if (arrows[index].style.transform == "rotate(-90deg)") {
          arrows[index].style.transform = "rotate(0deg)";
        } else {
          arrows[index].style.transform = "rotate(-90deg)";
        }
      } else {
        if (arrows[index].style.transform == "rotate(-90deg)") {
          arrows[index].style.transform = "rotate(-180deg)";
        } else {
          arrows[index].style.transform = "rotate(-90deg)";
        }
      }
    },
    async getFaqs() {
      await this.axios.get("faq/clint?skip=0&take=1000").then((res) => {
        this.faqs = res.data.items;
      });
    },
  },
};
</script>
