<template>
  <div class="contact-us">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="ContactUs" />
    </header>
    <main>
      <b-container>
        <section class="section-contact">
          <b-row>
            <b-col sm="12" md="6">
              <div class="contact-map">
                <ul>
                  <li data-aos="fade-left" data-aos-delay="0">
                    <p>
                      <i class="fas fa-envelope"></i>
                      <span>{{ $t("deanEmail") }}</span>
                    </p>
                    <p>
                      <a href="mailto:dean.office@nuc.edu.iq"
                        >dean.office@nuc.edu.iq</a
                      >
                    </p>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="300">
                    <p>
                      <i class="fas fa-envelope"></i>
                      <span>{{ $t("financeEmail") }}</span>
                    </p>
                    <p>
                      <a href="mailto:finance@nuc.edu.iq">finance@nuc.edu.iq</a>
                    </p>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="600">
                    <p>
                      <i class="fas fa-envelope"></i>
                      <span>{{ $t("ITEmail") }}</span>
                    </p>
                    <p>
                      <a href="mailto:info@nuc.edu.iq">info@nuc.edu.iq</a>
                    </p>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="900">
                    <p>
                      <i class="fas fa-phone"></i>
                      <span>{{ $t("accountsPhone") }}</span>
                    </p>
                    <p>6770</p>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="1200">
                    <p>
                      <i class="fas fa-phone"></i>
                      <span>{{ $t("ITPhone") }}</span>
                    </p>
                    <p>⁦+964 780 271 4344</p>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="1500">
                    <p>
                      <i class="fas fa-map-marker-alt"></i>
                      <span>{{ $t("Address") }}</span>
                    </p>
                    <p>{{ $t("collageAddress") }}</p>
                  </li>
                </ul>
                <div class="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3636.407787218771!2d44.35614317308429!3d33.30310932761026!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2siq!4v1548070543755"
                    style="border: 0"
                    allowfullscreen=""
                    width="470"
                    height="300"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="6">
              <div class="contact-form" data-aos="fade-up">
                <h3>{{ $t("general.contactUsByEmail") }}</h3>
                <b-form>
                  <b-form-group
                    id="input-group-1"
                    :label="$t('fullname')"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.full_name"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    :label="$t('email')"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.email"
                      type="email"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-3"
                    :label="$t('subject')"
                    label-for="input-3"
                  >
                    <b-form-input
                      id="input-3"
                      v-model="form.subject"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-4"
                    :label="$t('message')"
                    label-for="input-4"
                  >
                    <b-form-textarea
                      id="textarea"
                      v-model="form.message"
                      rows="10"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                  <a href="#" @click="sendMessage">
                    <button-fill text="send" />
                  </a>
                </b-form>
              </div>
            </b-col>
      </b-row>
        </section>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import { SID,TID,AID } from "@/main.js";
import emailjs from 'emailjs-com';
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      form: {},
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Contact Us`;
    document.querySelector('meta[name="title"]').setAttribute("content","Contact Us");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Contact Us");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `جامعة النسور  - التواصل معنا`;
    document.querySelector('meta[name="title"]').setAttribute("content","التواصل معنا");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","التواصل معنا");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {
    sendMessage() {
      if (
        this.form.email &&
        this.form.full_name &&
        this.form.subject &&
        this.form.message
      ) {
        let data = new FormData();
        Object.keys(this.form).forEach((key) => {
          data.append(key, this.form[key]);
        });
        this.axios
          .post("contact/clint", data)
          .then(() => {
            this.$swal("تم ارسال الرسالة بنجاح", "", "success");
            this.sendEmail()
            this.form = {};
          })
          .catch((err) => {
            if (err.response.data.email) {
              this.$swal(
                "يرجى التاكد من كتابة البريد الالكتروني بشكل صحيح",
                "",
                "warning"
              );
            } else if (err.response.data.phone) {
              this.$swal(
                "يرجى التاكد من رقم الهاتف",
                "رقم الهاتف غير صحيح",
                "warning"
              );
            } else {
              this.$swal("يرجى التاكد من مليء الحقول بشكل صحيح", "", "warning");
            }
          });
      } else {
        this.$swal("يرجى التاكد من مليء الحقول بشكل صحيح", "", "warning");
      }
    },
    sendEmail() {
      const fromName = "جامعة النسور"
      const fromEmail = "info@nuc.edu.iq"
      emailjs.send(SID,TID,{
          from_name: fromName,
         to_name: this.form.full_name, 
          message: "لقد تم استلام طليك بنجاح و سيتم التواصل معك عن طريق الايميل المرفق قريبا",
          to_email: this.form.email,
          reply_to:fromEmail
        },AID)
        .then((result) => {
          console.log(result)
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    },
  },
};
</script>
