<template>
  <div class="job job-application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header />
    </header>
    <main class="job-application__body">
      <section class="job-application__section">
        <b-container class="bg-white">
          <b-row>
            <b-col sm="12" md="6">
              <div class="job-application__form customize-form">
                <div class="job-form">
                  <h3 class="mb-5">
                    {{ $t('fullname') }}
                  </h3>
                  <b-form-group id="input-group-1" :label="$t('fullname')" label-for="input-1">
                    <b-form-input id="input-1" v-model="form.name" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" :label="$t('phonenumber')" label-for="input-2">
                    <b-form-input id="input-2" type="number" v-model="form.phone" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-3" :label="$t('graduationDegree')" label-for="input-3">
                    <b-form-input id="input-3" type="text" v-model="form.graguation" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-4" :label="$t('generalSpecialty')" label-for="input-4">
                    <b-form-input id="input-4" v-model="form.general_specialty" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-5" :label="$t('accurateSpecialty')" label-for="input-5">
                    <b-form-input id="input-5" type="text" v-model="form.accurate_specialty" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-6" :label="$t('cv')" label-for="input-6">
                    <b-form-file :label="$t('cv')" id="input-6" v-model="form.file_cv" :state="Boolean(form.file_cv)"
                      :placeholder="$t('cvfile')" drop-placeholder="قم بسحب الملف هنا" required></b-form-file>
                  </b-form-group>

                  <button @click="addJob">
                    <button-fill text="apply" />
                  </button>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="6" style="margin-top: 5rem">
              <h3 class="img-title"> وثيقة دعم التدريسين  </h3>
              <img src="@/assets/images/public-announce.jpg" alt="apply-job" style="width: 100%;height: 100%;object-fit: cover;object-position: center;" />
              </b-col>
            <b-col sm=" 12" md="12" style="margin-top: 2rem">
              <div class="post-sidebar">
                <div class="post-sidebar__popular-posts">
                  <h3>{{ $t('newJobs') }}</h3>
                  <ul>
                    <li v-for="job in jobs" :key="job.id">
                      <router-link :to="{ name: 'job', params: { id: job.id } }">
                        <img :src="getImage(job.image)" alt="" />
                      </router-link>
                      <div class="post-info">
                        <p>{{ shortenDate(job.created_at) }}</p>
                        <h4>
                          <router-link :to="{ name: 'job', params: { id: job.id } }">
                            {{ job.title }}
                          </router-link>
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        graguation: "",
        general_specialty: "",
        accurate_specialty: "",
        file_cv: "",
      },
      show: true,
      isMobile: false,
      jobs: null,
    };
  },
  mounted() {
    if (localStorage.getItem("lang") == "en") {
      document.title = ` Al-Nisour University   - Applying for a Job`;
      document.querySelector('meta[name="title"]').setAttribute("content", "Applying for a Job");
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.querySelector('meta[property="og:title"]').setAttribute("content", "Applying for a Job");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    } else {
      document.title = `جامعة النسور  - التقديم على الوظائف`;
      document.querySelector('meta[name="title"]').setAttribute("content", "التقديم على الوظائف");
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.querySelector('meta[property="og:title"]').setAttribute("content", "التقديم على الوظائف");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}favicon.ico`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getJobs();
  },
  methods: {
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    addJob() {
      let data = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] == null || this.form[key] == "") {
          this.$swal("يرجى مليء جميع الحقول", "حدث خطأ في التقديم", "error");
          return;
        }
      });
      data.append("name", this.form.name);
      data.append("phone", parseInt(this.form.phone));
      data.append("graguation", this.form.graguation);
      data.append("general_specialty", this.form.general_specialty);
      data.append("accurate_specialty", this.form.accurate_specialty);
      data.append("file_cv", this.form.file_cv);
      this.axios.post("job/application/form/clint", data).then(() => {
        this.$swal("تم التقديم على الوظيفة بنجاح", "", "success");
        Object.keys(this.form).forEach((key) => {
          this.form[key] = "";
        });
      });
    },
    getJobs() {
      this.axios.get("job/clint?skip=0&take=100").then((res) => {
        this.jobs = res.data.items.filter((item) => {
          return item.type == 1;
        });
      });
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
  },
};
</script>
<style>
.img-title{
  font-size: 27px;
    line-height: 1.2;
    margin: 22px 0;
}
.customize-form{
  border-left:1px solid #898282 ;
}
</style>