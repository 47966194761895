<template>
  <div class="job">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header />
    </header>
    <main class="job__body">
      <section class="job__details">
        <b-container class="bg-white">
          <div class="job-header">
            <img :src="getImage(jobDetails.image)" alt="" />
            <h2>{{ jobDetails.title }}</h2>
            <div class="date">
              <ul>
                <li>
                  <span><i class="fas fa-calendar-alt"></i></span>
                  <span>{{ $t("Publishedin") }} : </span>
                  <span>{{ shortenDate(jobDetails.created_at) }}</span>
                </li>
                <li>
                  <span><i class="fas fa-briefcase"></i></span>
                  <span>{{ $t("Numberofapplicants") }} : </span>
                  <span v-if="jobDetails.presentation_forms_count">{{
                    jobDetails.presentation_forms_count
                  }}</span>
                  <span v-else>0</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="job-details">
            <p id="text"></p>
            <div class="job-skills">
              <h3>{{ $t("Requiredskills") }}</h3>
              <!-- <ul>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi,
                  laboriosam?
                </li>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                <li>Lorem, ipsum dolor.</li>
                <li>Lorem ipsum dolor sit amet.</li>
                <li>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                </li>
                <li>Lorem, ipsum.</li>
              </ul> -->
              <p id="skills"></p>
            </div>
          </div>
          <div class="social-links">
            <ShareNetwork
              network="facebook"
              :url="href"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="vuejs,vite"
            >
              <span><i class="fab fa-facebook-f"></i></span>
            </ShareNetwork>
            <ShareNetwork
              network="twitter"
              :url="href"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="vuejs,vite"
            >
              <span><i class="fab fa-twitter"></i></span>
            </ShareNetwork>
            <ShareNetwork
              network="email"
              :url="href"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="vuejs,vite"
            >
              <span><i class="fas fa-envelope"></i></span>
            </ShareNetwork>
            <ShareNetwork
              network="linkedin"
              :url="href"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="vuejs,vite"
            >
              <span><i class="fab fa-linkedin-in"></i></span>
            </ShareNetwork>
          </div>
          <div class="job-form">
            <h3>{{ $t("jobform") }}</h3>
            <b-form>
              <b-form-group
                id="input-group-1"
                :label="$t('fullname')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                :label="$t('email')"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.email"
                  type="email"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                :label="$t('phonenumber')"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.phone"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                :label="$t('cv')"
                label-for="input-4"
              >
                <b-form-file
                  label="cv:"
                  id="input-4"
                  v-model="form.cv"
                  :state="Boolean(form.cv)"
                  :placeholder="$t('cvfile')"
                  drop-placeholder="$t('putFile')"
                  required
                ></b-form-file>
              </b-form-group>

              <button @click="applyToJob">
                <button-fill text="apply" />
              </button>
            </b-form>
          </div>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import Quill from "quill";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        phone: null,
        cv: null,
      },
      jobDetails: null,
      isMobile: false,
    };
  },
  async mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    await this.getJob();
    let des_data = [];
    des_data.push(JSON.parse(this.jobDetails.description));
    let des_article = document.createElement("article");
    let des_quill = new Quill(des_article, {});
    des_quill.enable(false);
    des_quill.setContents(des_data[0].ops);
    let des_discription = document.getElementById("text");
    des_discription.appendChild(des_article);

    // skills
    let skills_data = [];
    skills_data.push(JSON.parse(this.jobDetails.skills));
    let skills_article = document.createElement("article");
    let skills_quill = new Quill(skills_article, {});
    skills_quill.enable(false);
    skills_quill.setContents(skills_data[0].ops);
    let skills_discription = document.getElementById("skills");
    skills_discription.appendChild(skills_article);
  },
  methods: {
    async getJob() {
      await this.axios.get(`job/clint/${this.$route.params.id}`).then((res) => {
        this.jobDetails = res.data;
        this.changeMeta(res.data);
      });
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    applyToJob() {
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] == null || this.form[key] == "") {
          this.$swal("يرجى مليء جميع الحقول", "حدث خطأ في التقديم", "error");
          return;
        }
      });
      let data = new FormData();
      data.append("name", this.form.name);
      data.append("phone", this.form.phone);
      data.append("email", this.form.email);
      data.append("file_cv", this.form.cv);
      data.append("job_id", this.$route.params.id);
      this.axios
        .post("presentation/job/clint", data)
        .then(() => {
          this.$swal("تم التقديم على الوظيفة بنجاح", "", "success");
          Object.keys(this.form).forEach((key) => {
            this.form[key] = "";
          });
        })
        .catch(() => {});
    },
    getImage(path) {
      return `${filesUrl}${path}`;
    },
    changeMeta(item){
      document.querySelector('meta[name="title"]').setAttribute("content",`${item.title}`);
      document.querySelector('meta[name="description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:title"]').setAttribute("content",`${item.title}`);
      document.querySelector('meta[property="og:description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}${item.image}`);
      // document.getElementById("icon").setAttribute("href",`${filesUrl}${item.image}`); 
      if(localStorage.getItem("lang")=="ar"){
      document.title = `جامعة النسور  - ${item.title}`;
      }else{
      document.title = ` Al-Nisour University   - ${item.title}`;
      }
    }
  },
};
</script>
