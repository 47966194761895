<template>
  <div class="dep-info">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="fees" />
    </header>
    <main>
      <section class="section-deps">
        <b-container>
          <section
            data-aos="fade-left"
            class="section section-lessons"
            v-for="dep in installments"
            :key="dep.id"
          >
            <div v-if="dep.installment.length !== 0">
              <h3>
                {{ dep.name }}
              </h3>
              <div>
                <div class="tbl-header">
                  <table cellpadding="0" cellspacing="0" border="0">
                    <thead>
                      <tr>
                        <th>{{ $t("totalFees") }}</th>
                        <th>{{ $t("stage") }}</th>
                        <th>{{ $t("firstBatch") }}</th>
                        <th>{{ $t("secondBatch") }}</th>
                        <th>{{ $t("thirdBatch") }}</th>
                        <th>{{ $t("fourthBatch") }}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="tbl-content">
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    border="0"
                    v-for="installment in dep.installment"
                    :key="installment.id"
                  >
                    <tbody>
                      <tr>
                        <td>
                          {{ installment.total_batch }}
                        </td>
                        <td>{{ installment.stage }}</td>
                        <td>{{ installment.first_batch }}</td>
                        <td>{{ installment.second_batch }}</td>
                        <td>{{ installment.third_batch }}</td>
                        <td>{{ installment.fourth_batch }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";

export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      installments: null,
      totalInstallment: 0,
      isMobile: false,
    };
  },
  async mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - FEES `;
    document.querySelector('meta[name="title"]').setAttribute("content","FEES");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","FEES");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `جامعة النسور  - الاقساط الدراسية`;
    document.querySelector('meta[name="title"]').setAttribute("content","الاقساط الدراسية");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","الاقساط الدراسية");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getInstallment();
  },
  methods: {
    getInstallment() {
      this.axios.get(`since/deparment/clint?skip=0&take=1000`).then((res) => {
        this.installments = res.data.items;
      });
    },
  },
};
</script>
