<template>
  <div class="job profile">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header />
    </header>
    <main class="job__body">
      <section class="job__details">
        <b-container class="bg-white">
          <div class="profile-header">
            <div class="user-image">
              <i class="fas fa-user user-icon"></i>
              <div class="image-container">
                <img :src="userImage" v-if="userImage" />
                <img :src="getImage(form.image)" v-else />
              </div>
              <label for="image" class="edit">
                <i class="fas fa-camera"></i>
              </label>
              <input
                type="file"
                name="image"
                id="image"
                ref="files"
                @change="changeFile"
              />
            </div>
            <h3>{{ userName }}</h3>
          </div>
          <div class="job-form">
            <b-form>
              <b-form-group
                id="input-group-1"
                :label="$t('fullname')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.full_name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                :label="$t('email')"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.email"
                  type="email"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                :label="$t('phonenumber')"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.phone"
                  required
                ></b-form-input>
              </b-form-group>

              <button @click="saveInfo">
                <button-fill text="save" />
              </button>
            </b-form>
          </div>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main.js";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  title() {
    if (localStorage.getItem("lang") == "ar") {
      return "جامعة النسور";
    } else {
      return "Al-Nisour University ";
    }
  },
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      form: {
        email: null,
        full_name: null,
        phone: null,
        image: null,
      },
      userEmail: null,
      userImage: null,
      userName: null,
      isMobile: false,
    };
  },
  mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.axios.get("users/details").then((res) => {
        this.form = res.data;
        this.userName = res.data.full_name;
        this.userEmail = res.data.email;
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    saveInfo() {
      let data = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (this.form[key]) {
          if (key !== "email") {
            data.append(key, this.form[key]);
          } else {
            if (this.form[key] !== this.userEmail) {
              data.append(key, this.form[key]);
            }
          }
        }
      });
      this.axios
        .post("me/update/profile", data)
        .then((res) => {
          this.form = res.data;
          this.userName = res.data.full_name;
          this.$swal("تم تعديل المعلومات بنجاح", "", "success");
        })
        .catch(() => {
          this.$swal("حدث خطأ, لم يتم تعديل المعلومات", "", "error");
        });
    },
    changeFile(e) {
      this.form.image = e.target.files[0];
      this.userImage = URL.createObjectURL(e.target.files[0]);
    },
  },
};
</script>
