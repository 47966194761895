<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="404" />
    </header>
    <!-- <main>
      <section class="section-details">
        <b-container>
          <h4 class="mb-5">تعرف على الخطة الخمسية</h4>
          <p class="mb-5">
            {{ $t("planingDes") }}
          </p>
          <a
            href="http://localhost:8080/lib/pdfjs/web/viewer.html?file=fifth-plan.pdf"
            target="_blank"
          >
            <button-fill text="اقرأ المزيد" />
          </a>
        </b-container>
      </section>
    </main> -->
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
// import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  title() {
    if (localStorage.getItem("lang") == "ar") {
      return "جامعة النسور";
    } else {
      return "Al-Nisour University ";
    }
  },
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    // ButtonFill,
    MobileNav,
  },
  data() {
    return {
      name: "",
      path: "lib/pdfjs/web/viewer.html",
      isMobile: false,
    };
  },
  mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}
</style>
