<template>
    <div class="dep-info">
      <header>
        <topbar />
        <navbar v-show="isMobile !== true" />
        <mobile-nav v-show="isMobile" />
        <section-header text="relatedlinks" />
      </header>
      <main>
        <section class="tracking-body">
          <div class="container-center">
            <div class="tracking-headertext">{{ $t("relatedlinks") }}</div>
          </div>
          <div class="container-center">
            <div class="custom-input">
            <input type="text" v-model="title" placeholder="ابحث عن الجامعة ....">
          </div>
          </div>
        </section>
        <section class="section-deps" >
          <b-container >
            <section
              data-aos="fade-left"
              class="section section-lessons"
            >
              <div v-if="links.length !== 0">
                <div>
                  <div class="tbl-header">
                    <table cellpadding="0" cellspacing="0" border="0">
                      <thead>
                        <tr>
                          <th>{{ $t("University") }}</th>
                          <th>{{ $t("link") }}</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="tbl-content">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                      v-for="l in links"
                      :key="l.id"
                    >
                      <tbody>
                        <tr>
                          <td>{{ l.title }}</td>
                          <td><a :href="l.link" ><p style="color:blue">{{ l.link }}</p></a></td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </b-container>
        </section>
      </main>
      <footer>
        <footer-component />
      </footer>
    </div>
  </template>
  
  <script>
  // import { filesUrl } from "@/main";
  import MobileNav from "@/components/layout/MobileNav.component.vue";
  import Topbar from "@/components/layout/Topbar.component.vue";
  import Navbar from "@/components/layout/Navbar.component.vue";
  import SectionHeader from "@/components/layout/SectionHeader.component.vue";
  import FooterComponent from "@/components/layout/FooterComponent.component.vue";
  
  export default {
    components: {
      Topbar,
      Navbar,
      SectionHeader,
      FooterComponent,
      MobileNav,
    },
    data() {
      return {
        links: [],
        title: "",
        isMobile: false,
      };
    },
    async mounted() {
      if(localStorage.getItem("lang")=="en"){
      document.title = ` Al-Nisour University   - related links `;
      document.querySelector('meta[name="title"]').setAttribute("content","FEES");
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.querySelector('meta[property="og:title"]').setAttribute("content","FEES");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "");
      }else{
      document.title = `جامعة النسور  - روابط ذات العلاقة`;
      document.querySelector('meta[name="title"]').setAttribute("content","الاقساط الدراسية");
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.querySelector('meta[property="og:title"]').setAttribute("content","الاقساط الدراسية");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "");
      }
      document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
      // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
      if (window.innerWidth > 991) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
      this.getrelatedlinks();
    },
    watch: {
    title: {
      handler: function () {
        this.getrelatedlinks();
      },
    },
  },
    methods: {
      getrelatedlinks() {
        this.axios.get(`relatedlinks?title=${this.title}`).then((res) => {
          this.links = res.data.items;
        });
      },
    },
  };
  </script>
  