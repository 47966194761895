<template>
  <div class="lecturtes">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="Course guide" />
    </header>
    <main>
      <section class="section-stages">
        <section class="tracking-body">
          <div class="container-center">
            <div class="tracking-headertext">{{ $t("Course guide") }}</div>
          </div>
        </section>
        
        <b-container>
          <b-row>
            <b-col sm="12" md="3" v-for="stage in stages" :key="stage.id">
              <div class="stage" @click="getLectures(stage.id)">
                <div class="stage-name">
                  {{ stage.name }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="section-lectures" v-if="lectures.length !== 0">
        <b-container>
          <b-row class="mt-5">
            <b-col
              sm="12"
              md="4"
              class="mb-5"
              v-for="lecture in lectures"
              :key="lecture.id"
            >
              <div
                class="lecture"
                @click="
                  getFiles(
                    lecture.id,
                    lecture.teacher.name,
                    lecture.teacher.id,
                    lecture.celbas
                  )
                "
              >
                <div class="lecture-name">
                  {{ lecture.name }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="section-files" v-if="files.length !== 0">
        <b-container>
          <b-row>
            <b-col sm="12" md="6" class="mb-3">
              <div
                class="file"
                style="transition: all 0.3s; color: #fff !important"
              >
                {{ $t("lecturer") }} :
                <router-link
                  :to="{
                    name: 'staff-profile',
                    params: { id: lecturer_id },
                  }"
                >
                  {{ lecturer }}
                </router-link>
              </div>
            </b-col>
            <b-col sm="12" md="6" class="mb-3">
              <div
                class="file"
                style="transition: all 0.3s; color: #fff !important"
              >
                {{ $t("celbas") }} :
                <a
                  :href="'https://nuc.edu.iq/Al-Nisour/public/' + celbas"
                  target="_blank"
                  >{{ $t("clickhere") }}</a
                >
              </div>
            </b-col>
            <b-col
              sm="12"
              md="6"
              class="mb-3"
              v-for="file in files"
              :key="file.id"
            >
              <div class="file">
                <a
                  :href="
                    'https://nuc.edu.iq/Al-Nisour/public/' + file.file
                  "
                  target="_blank"
                  >{{ file.name }}</a
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import { mapState } from "vuex";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
      stages: [],
      lectures: [],
      files: [],
      celbas: null,
      lecturer: null,
      lecturer_id: null,
    };
  },
  computed: { ...mapState(["depId"]) },
  watch: {
    depId: async function () {
      await this.getStages();
    },
  },
  async mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    await this.getStages();
  },
  methods: {
    async getStages() {
      await this.axios
        .get(`stage/clint/department/${this.$route.params.id}`)
        .then((res) => {
          this.stages = res.data.stages;
          this.changeMeta(res.data);
        });
    },
    getLectures(id) {
      this.axios
        .get(`lecture/clint?take=100&skip=0&stage_id=${id}`)
        .then((res) => {
          this.lectures = res.data.items;
        });
    },
    getFiles(id, name, lecturer_id, celbas) {
      this.lecturer = name;
      this.celbas = celbas;
      this.lecturer_id = lecturer_id;
      this.axios.get(`lecture/clint/${id}`).then((res) => {
        this.files = res.data.files;
      });
    },
    changeMeta(item) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", `${item.about.vision}`);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", `${item.about.vision}`);
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", `${filesUrl}${item.logo}`);
      document
        .getElementById("icon")
        .setAttribute("href", `${filesUrl}${item.logo}`);
      if (localStorage.getItem("lang") == "ar") {
        document.title = `جامعة النسور  - ${item.name} - محاضرات القسم`;
        document
          .querySelector('meta[name="title"]')
          .setAttribute("content", `${item.name} - محاضرات القسم`);
      } else {
        document.title = ` Al-Nisour University   - ${item.name} - Department lectures`;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", `${item.name} - Department lectures`);
      }
    },
  },
};
</script>
