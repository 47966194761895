<template>
  <div class="dep-info staff">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header :text="`${$t('about')} ${depInfo.name}`" />
    </header>
    <main>
      <section class="section-deps ">
        <b-container>
          <section class="section section-vision" data-aos="fade-left">
            <h3>{{ $t("Sectionvision") }}</h3>
            <div v-if="depInfo.length !== 0">
              <p id="vision"></p>
            </div>
          </section>
          <section class="section section-message" data-aos="fade-right">
            <h3>{{ $t("SectionMessage") }}</h3>
            <div v-if="depInfo.length !== 0">
              <p id="message" class="d-flex"></p>
            </div>
          </section>
          <section class="section section-goals" data-aos="fade-left">
            <h3>{{ $t("SectionGoals") }}</h3>
            <div v-if="depInfo.length !== 0">
              <p id="goals" class="d-flex"></p>
            </div>
          </section>
          <section class="section section-lessons" data-aos="fade-right">
            <h3>{{ $t("depFees") }}</h3>
            <div>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>{{ $t("stage") }}</th>
                      <th>{{ $t("totalFees") }}</th>
                      <th>{{ $t("firstBatch") }}</th>
                      <th>{{ $t("secondBatch") }}</th>
                      <th>{{ $t("thirdBatch") }}</th>
                      <th>{{ $t("fourthBatch") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr
                      v-for="installment in installments"
                      :key="installment.id"
                    >
                      <td>
                        {{ installment.stage }}
                      </td>
                      <td>{{ installment.total_batch }}</td>
                      <td>{{ installment.first_batch }}</td>
                      <td>{{ installment.second_batch }}</td>
                      <td>{{ installment.third_batch }}</td>
                      <td>{{ installment.fourth_batch }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          </b-container>
      </section>
          <section class="staffs " id="teachers">
            <b-container>
              <b-row>
                <b-col v-if="manager!=null"
                  sm="12"
                  md="6"
                  class="mb-5"
                >
                <div class="header-title">
                  <h3>{{ $t("depmanager") }}</h3>
                </div>
                  <div 
                    class="staff__card"
                    data-aos="fade"
                  >
                    <div class="staff__card--imageplaceholder">
                      <img :src="getImage(manager.image)" alt="" />
                      <div class="overlay">
                        <router-link
                          :to="{
                            name: 'staff-profile',
                            params: { id: manager.id },
                          }"
                        >
                          <button-fill text="readmore" />
                        </router-link>
                      </div>
                    </div>
                    <div class="staff__card--content">
                      <h3>
                        <router-link
                          :to="{
                            name: 'staff-profile',
                            params: { id: manager.id },
                          }"
                        >
                          {{ manager.title }}
                        </router-link>
                      </h3>
                      <p class="spicialized">
                        {{ manager.general_specialty }},
                        {{ manager.accurate_specialty }}
                      </p>
                      <p class="details">
                        {{ manager.description }}
                      </p>
                    </div>
                  </div>
                </b-col>
                <b-col v-if="coordinator!=null"
                  sm="12"
                  md="6"
                  class="mb-5"
                >
                <div class="header-title">
                  <h3>{{ $t("depcoor") }}</h3>
                </div>
                  <div 
                    class="staff__card"
                    data-aos="fade"
                  >
                    <div class="staff__card--imageplaceholder">
                      <img :src="getImage(coordinator.image)" alt="" />
                      <div class="overlay">
                        <router-link
                          :to="{
                            name: 'staff-profile',
                            params: { id: coordinator.id },
                          }"
                        >
                          <button-fill text="readmore" />
                        </router-link>
                      </div>
                    </div>
                    <div class="staff__card--content">
                      <h3>
                        <router-link
                          :to="{
                            name: 'staff-profile',
                            params: { id: coordinator.id },
                          }"
                        >
                          {{ coordinator.title }}
                        </router-link>
                      </h3>
                      <p class="spicialized">
                        {{ coordinator.general_specialty }},
                        {{ coordinator.accurate_specialty }}
                      </p>
                      <p class="details">
                        {{ coordinator.description }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </section>
          <section class="section-deps ">
        <b-container>
          <section class="section section-links">
            <h3>{{ $t("Additionallinks") }}</h3>
            <ul class="links column-container">
              <!-- exists -->
              <li v-if="dep.teachers.length !== 0">
                <router-link
                  :to="{ name: 'staff', params: { id: this.$route.params.id } }"
                >
                <div class="column blue">
                  <i class="icon fas fa-chalkboard-teacher"></i>
                  <p style="font-size:30px">{{ $t("teachingstaff") }}</p>
                </div>
                  
                </router-link>
              </li>
              <li >
                <a
                      @click.prevent="toTable(dep)"
                      >
                    
                <div class="column blue">
                  <i class="icon fas fa-table"></i>
                  <p style="font-size:30px">{{ $t("SectionTable") }}</p>
                </div>
                    </a>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'lectures',
                    params: { id: this.$route.params.id, depName: dep.name },
                  }"
                >
                <div class="column blue">
                  <i class="icon fas fa-book"></i>
                  <p style="font-size:30px">{{ $t("lectures") }}</p>
                </div>
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'dep-news',
                    params: { id: this.$route.params.id },
                  }"
                >
                <div class="column blue">
                  <i class="icon fas fa-newspaper"></i>
                  <p style="font-size:30px">{{ $t("depNews") }}</p>
                </div>
                  
                </router-link>
              </li>
              
              <li>
                <router-link
                  :to="{
                    name: 'students-research',
                    params: { id: this.$route.params.id },
                  }"
                >
                
                <div class="column blue">
                  <i class="icon fas fa-microscope"></i>
                  <p style="font-size:30px">{{ $t("studentsResearches") }}</p>
                </div>
                  
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'dep-researches',
                    params: { id: this.$route.params.id },
                  }"
                >
                
                <div class="column blue">
                  <i class="icon fas fa-flask"></i>
                  <p style="font-size:30px">{{ $t("depResearches") }}</p>
                </div>
                  
                </router-link>
              </li>
              <li>
                <a @click.prevent="tograduated(dep)">
              
                <div class="column blue">
                  <i class="icon fas fa-graduation-cap"></i>
                  <p style="font-size:30px">{{$t("std_graguate.studentsGraguate")}}</p>
                </div></a>
              </li>
              <li>
                <a
                  @click="toLink('https://nuc.edu.iq/Al-Nisour/public/uploads/university-calendar2023-2024.pdf')"
                  href="https://nuc.edu.iq/Al-Nisour/public/uploads/university-calendar2023-2024.pdf"
                  target="_blank"
                  >
                  <div class="column blue">
                    <i class="icon fas fa-cloud"></i>
                    <p style="font-size:30px">{{ $t("e-services") }}</p>
                  </div>
                  </a
                >
              </li>
              <li>
                <a
                  @click="toLink('https://e-learn.nuc.edu.iq/videos')"
                  href="https://e-learn.nuc.edu.iq/videos"
                  target="_blank"
                  >
                  <div class="column blue">
                    <i class="rticle icon fas fa-play"></i>
                    <p style="font-size:30px">{{ $t("videolearn") }}</p>
                  </div></a
                >
              </li>
              <li>
                <a
                  @click="toLink('https://nuc.edu.iq/Al-Nisour/public/uploads/university-calendar2023-2024.pdf')"
                  href="https://nuc.edu.iq/Al-Nisour/public/uploads/university-calendar2023-2024.pdf"
                  target="_blank"
                  >
                  <div class="column blue">
                    <i class="rticle icon fas fa-calendar"></i>
                    <p style="font-size:30px">{{ $t("university-calendar") }}</p>
                  </div></a
                >
              </li>
              <li v-if="!status">
                <a
                  @click="changeStatus()"
                  target="_blank"
                  >
                  <div class="column blue">
                    <i class="icon fas fa-book"></i>
                    <p style="font-size:30px">{{ $t("Academic description") }}</p>
                  </div></a
                >
              </li>
              <li v-if="status==true">
                <div  v-for="des in about.descibation"
                      :key="des.id">
                        <button class="custom-button" @click="openPDF(des.ar)">{{ $t("Academic description of the academic year") }} {{ des.year.begin }}-{{ des.year.end }} {{ $t("byarabic") }}</button>
                        <button class="custom-button" @click="openPDF(des.en)">{{ $t("Academic description of the academic year") }} {{ des.year.begin }}-{{ des.year.end }} {{ $t("byenglish") }}</button>

              </div>
              </li>
            </ul>
          </section>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>
<style scoped>
.column-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  flex: 1;
  text-align: center;
  padding: 20px;
  color: white;
  border-radius: 10px;
  margin: 10px;
}

.blue {
  background-color: #182449;
}

.green {
  background-color: green;
}

.white {
  background-color: white;
}

.icon {
  font-size: 24px;
  margin-bottom: 10px;
}
</style>
<script>
import { filesUrl } from "@/main";
import Quill from "quill";
import { mapState } from "vuex";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";

export default {
  components: {
    Topbar,ButtonFill,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      depInfo: [],
      about: [],
      calendar:'https://nuc.edu.iq/Al-Nisour/public/uploads/university-calendar2023-2024.pdf',
      status:false,
      installments: null,
      totalInstallment: 0,
      isMobile: false,
      dep: [],
      coordinator:null,
      manager:null,
      link:null
    };
  },
  computed: { ...mapState(["depId"]) },
  watch: {
    depId: async function () {
      this.getDep();
      await this.getDepInfo();
      await this.getInstallment();
      // await this.getInstallment();
      // vision
      let vision = [];
      vision.push(JSON.parse(this.about.vision));
      let vision_article = document.createElement("article");
      let vision_quill = new Quill(vision_article, {});
      vision_quill.enable(false);
      vision_quill.setContents(vision[0].ops);
      let vision_discription = document.getElementById("vision");
      vision_discription.innerHTML = "";
      vision_discription.appendChild(vision_article);
      // message
      let message = [];
      message.push(JSON.parse(this.about.message));
      let message_article = document.createElement("article");
      let message_quill = new Quill(message_article, {});
      message_quill.enable(false);
      message_quill.setContents(message[0].ops);
      let message_discription = document.getElementById("message");
      message_discription.innerHTML = "";
      message_discription.appendChild(message_article);
      // goals
      let goals = [];
      goals.push(JSON.parse(this.about.goals));
      let goals_article = document.createElement("article");
      let goals_quill = new Quill(goals_article, {});
      goals_quill.enable(false);
      goals_quill.setContents(goals[0].ops);
      let goals_discription = document.getElementById("goals");
      goals_discription.innerHTML = "";
      goals_discription.appendChild(goals_article);
    },
  },
  async mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getDep();
    await this.getDepInfo();
    await this.getInstallment();

    // vision
    let vision = [];
    vision.push(JSON.parse(this.about.vision));
    let vision_article = document.createElement("article");
    let vision_quill = new Quill(vision_article, {});
    vision_quill.enable(false);
    vision_quill.setContents(vision[0].ops);
    let vision_discription = document.getElementById("vision");
    vision_discription.appendChild(vision_article);
    // message
    let message = [];
    message.push(JSON.parse(this.about.message));
    let message_article = document.createElement("article");
    let message_quill = new Quill(message_article, {});
    message_quill.enable(false);
    message_quill.setContents(message[0].ops);
    let message_discription = document.getElementById("message");
    message_discription.appendChild(message_article);
    // goals
    let goals = [];
    goals.push(JSON.parse(this.about.goals));
    let goals_article = document.createElement("article");
    let goals_quill = new Quill(goals_article, {});
    goals_quill.enable(false);
    goals_quill.setContents(goals[0].ops);
    let goals_discription = document.getElementById("goals");
    goals_discription.appendChild(goals_article);
  },
  methods: {
    tograduated(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-graduated",
          params: { id: dep.id },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-graduated",
          params: { id: dep.id },
        });
      }
    },
    async getDepInfo() {
      await this.axios
        .get(`about/department/${this.$route.params.id}`)
        .then((res) => {
          this.depInfo = res.data;
          this.about = res.data.about;
          this.changeMeta(res.data)
        });
    },
    async getInstallment() {
      await this.axios
        .get(`section/installment/department/${this.$route.params.id}`)
        .then((res) => {
          this.installments = res.data;
          console.log(res.data)
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    getDep() {
      this.axios
        .get(`since/deparment/clint/${this.$route.params.id}`)
        .then((res) => {
          this.manager = res.data.manager
          this.coordinator = res.data.coordinator
          this.link = res.data.link
          this.dep = res.data;
        });
    },
    toTable(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "table",
          params: { id: dep.id, depName: dep.name },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "table",
          params: { id: dep.id, depName: dep.name },
        });
      }
    },
    toLink(link) {
      window.open(link, "_blank");
    },
    changeStatus(){
      this.status=!this.status
      console.log(this.status)
    },
    openPDF(link){
      window.open(link, "_blank");
    },
    changeMeta(item){
      document.querySelector('meta[name="description"]').setAttribute("content", `${item.about.vision}`);
      document.querySelector('meta[property="og:description"]').setAttribute("content", `${item.about.vision}`);
      document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}${item.logo}`);
      document.getElementById("icon").setAttribute("href",`${filesUrl}${item.logo}`); 
      if(localStorage.getItem("lang")=="ar"){
      document.title = `جامعة النسور  - نبذة عن ${item.name} `;
      document.querySelector('meta[name="title"]').setAttribute("content",`  - نبذة عن ${item.name}`);
      }else{
      document.title = ` Al-Nisour University   - About ${item.name}`;
      document.querySelector('meta[property="og:title"]').setAttribute("content",`About ${item.name}`);
      }
    }
  },
};
</script>
