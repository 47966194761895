<template>
  <div class="dep-info research">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="depResearches" />
    </header>
    <main>
      <section class="section-deps">
        <b-container>
          <section data-aos="fade-left" class="section section-lessons">
            <h3>
              {{ $t("depResearches") }}
            </h3>
            <div>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t("researchTitle") }}</th>
                      <th>{{ $t("teacher") }}</th>
                      <th>{{ $t("dep") }}</th>
                      <th>{{ $t("date") }}</th>
                      <th>{{ $t("magazin2") }}</th>
                      <th>{{ $t("researchLink") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr
                      v-for="(research, index) in installments"
                      :key="research.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ research.title }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'staff-profile',
                            params: { id: research.teacher.id },
                          }"
                        >
                          {{ research.teacher.name }}
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'about-dep',
                            params: { id: research.department.id },
                          }"
                        >
                          {{ research.department.name }}
                        </router-link>
                      </td>
                      <td>{{ research.publish_date }}</td>
                      <td>{{ research.magazine }}</td>
                      <td>
                        <a :href="research.link"><i class="fas fa-link"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </b-container>
      </section>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import { mapState } from "vuex";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";

export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      installments: null,
      totalInstallment: 0,
      isMobile: false,
      perPage: 10,
      currentPage: 1,
      totalCount: "",
    };
  },
  computed: { ...mapState(["depId"]) },
  watch: {
    depId: async function () {
      await this.getResearches();
    },
    currentPage: {
      handler: function () {
        this.getResearches();
      },
    },
  },
  async mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getResearches();
  },
  methods: {
    async getResearches() {
      let skip = this.currentPage - 1;
      await this.axios
        .get(
          `research/clint?skip=${skip}&take=${this.perPage}&department_id=${this.$route.params.id}`
        )
        .then((res) => {
          this.installments = res.data.items;
          this.totalCount = res.data.totalCount;
          this.getDep();
        });
    },
    getDep() {
      this.axios
        .get(`since/deparment/clint/${this.$route.params.id}`)
        .then((res) => {
          this.changeMeta(res.data);
        });
    },
    changeMeta(item){
      document.querySelector('meta[name="description"]').setAttribute("content", `${item.about.vision}`);
      document.querySelector('meta[property="og:description"]').setAttribute("content", `${item.about.vision}`);
      document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}${item.logo}`);
      document.getElementById("icon").setAttribute("href",`${filesUrl}${item.logo}`); 
      if(localStorage.getItem("lang")=="ar"){
      document.title = `جامعة النسور  - بحوث ${item.name} `;
      document.querySelector('meta[name="title"]').setAttribute("content",`  - بحوث ${item.name}`);
      }else{
      document.title = ` Al-Nisour University   - ${item.name} Researches`;
      document.querySelector('meta[property="og:title"]').setAttribute("content",`${item.name} Researches`);
      }
    }
  },
};
</script>
