<template>
  <div class="staff">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="DepartmenTeachers" />
    </header>
    <main>
      <section class="staffs" id="teachers">
        <b-container>
          <b-row>
            <b-col
              sm="12"
              md="6"
              class="mb-5"
              v-for="(teacher, index) in teachers"
              :key="teacher.id"
            >
              <div 
                class="staff__card"
                data-aos="fade"
                :data-aos-delay="`${index + 5}00`"
              >
                <div class="staff__card--imageplaceholder">
                  <img :src="getImage(teacher.image)" alt="" />
                  <div class="overlay">
                    <router-link
                      :to="{
                        name: 'staff-profile',
                        params: { id: teacher.id },
                      }"
                    >
                      <button-fill text="readmore" />
                    </router-link>
                  </div>
                </div>
                <div class="staff__card--content">
                  <h3>
                    <router-link
                      :to="{
                        name: 'staff-profile',
                        params: { id: teacher.id },
                      }"
                    >
                      {{ teacher.name }}
                    </router-link>
                  </h3>
                  <p class="spicialized">
                    {{ teacher.general_specialty }},
                    {{ teacher.accurate_specialty }}
                  </p>
                  <p class="details">
                    {{ teacher.description }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totaCount"
              :per-page="perPage"
              aria-controls="teachers"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import { mapState } from "vuex";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    ButtonFill,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      teachers: [],
      isMobile: false,
      totaCount: "",
    };
  },
  watch: {
    depId: async function () {
      await this.getTeachers();
    },
    currentPage: {
      handler: function () {
        this.getTeachers();
      },
    },
  },
  computed: {
    ...mapState(["depId"]),
    rows() {
      return this.teachers.length;
    },
  },
  mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getTeachers();
  },
  methods: {
    getTeachers() {
      const filters = []
      let skip = this.currentPage - 1;
      this.axios
        .get(
          `teacher/clint?skip=${skip}&take=${this.perPage}&filter[department_id]=${this.$route.params.id}`
        )
        .then((res) => {
          res.data.items.forEach(element => {
            if(element.name!="new profile"){
            filters.push(element)
            }
          });
          this.teachers = filters
          this.rows = res.data.items.totalCount;
          this.totaCount = res.data.totalCount;
          this.getDep();
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    getDep() {
      this.axios
        .get(`since/deparment/clint/${this.$route.params.id}`)
        .then((res) => {
          this.changeMeta(res.data);
        });
    },
    changeMeta(item){
      document.querySelector('meta[name="description"]').setAttribute("content", `${item.about.vision}`);
      document.querySelector('meta[property="og:description"]').setAttribute("content", `${item.about.vision}`);
      document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}${item.logo}`);
      document.getElementById("icon").setAttribute("href",`${filesUrl}${item.logo}`); 
      if(localStorage.getItem("lang")=="ar"){
      document.title = `جامعة النسور  - تدريسيوا ${item.name} `;
      document.querySelector('meta[name="title"]').setAttribute("content",`  - تدريسيوا ${item.name}`);
      }else{
      document.title = ` Al-Nisour University   - ${item.name} Staff`;
      document.querySelector('meta[property="og:title"]').setAttribute("content",`${item.name} Staff`);
      }
    }
  },
};
</script>
