<template>
  <div class="application dep-info">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="energy.e15" />
    </header>
    <main>
       <section class="section-d" data-aos="fade-up">
        <b-container>
          <p class="mb-5">
            {{ $t("energy.e16") }}
          </p>
        </b-container>
      </section>
      <section class="section-d" data-aos="fade-up">
        <b-container>
          <h4>{{ $t("energy.e27") }}</h4>
          <p class="mb-5">
            {{ $t("energy.e28") }}
          </p>
        </b-container>
      </section>
      <section class="section-d" data-aos="fade-up">
        <b-container>
          <h4>{{ $t("energy.e29") }}</h4>
          <p class="mb-5">
            {{ $t("energy.e30") }}
          </p>
        </b-container>
      </section>
      <section class="section-deps">
        <b-container>
      <section class="section section-lessons" data-aos="fade-right">
            <h3>{{ $t("energy.e17") }}</h3>
            <div>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>{{ $t("energy.e18") }}</th>
                      <th>{{ $t("energy.e19") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr>
                      <td>{{ $t("energy.e21") }}</td>
                      <td>{{ $t("energy.e19") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("energy.e22") }}</td>
                      <td>{{ $t("energy.e20") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("energy.e23") }}</td>
                      <td>{{ $t("energy.e20") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("energy.e24") }}</td>
                      <td>{{ $t("energy.e20") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("energy.e25") }}</td>
                      <td>{{ $t("energy.e20") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("energy.e26") }}</td>
                      <td>{{ $t("energy.e20") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          </b-container>
      </section>
      <section class="section-d">
        <b-container>
          <a class="links-button"
            :href="'energy-management-system'" 
            target="_blank"
          >
            <button-fill text="energy management system" />
          </a>
          <a class="links-button"
            :href="'ISO50001'" 
            target="_blank"
          >
            <button-fill text="ISO50001" />
          </a>
          <a class="links-button"
            :href="'Energy-Researches'" 
            target="_blank"
          >
            <button-fill text="energy.e29" />
          </a>
          <a class="links-button"
            :href="'Energy-Workshops'" 
            target="_blank"
          >
            <button-fill text="energy.e31" />
          </a>
        </b-container>
      </section>
      
      <!-- <section class="section section-links">
            <h3>{{ $t("Additionallinks") }}</h3>
            <ul class="links">
              <li>
                <router-link
                  :to="{ name: 'staff' }"
                >
                  {{ $t("teachingstaff") }}
                </router-link>
              </li>
              <li >
                <a >
                  {{ $t("SectionTable") }}
                </a>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'lectures',
                  }"
                >
                  {{ $t("lectures") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'students-research',
                  }"
                >
                  {{ $t("studentsResearches") }}
                </router-link>
              </li>
              <li >
                <router-link
                  :to="{
                    name: 'dep-news',
                  }"
                >
                  {{ $t("depNews") }}
                </router-link>
              </li>
            </ul>
          </section> -->
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { Url } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      name: "",
      path: "lib/pdfjs/web/viewer.html",
      isMobile: false,
      host: window.location.host,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Governance meeting minutes`;
    document.querySelector('meta[name="title"]').setAttribute("content","Governance meeting minutes");
    document.querySelector('meta[name="description"]').setAttribute("content", "The position of governance within Al-Nisour University ");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Governance meeting minutes");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The position of governance within Al-Nisour University ");
    }else{
    document.title = `جامعة النسور  - محضر اجتماع الحوكمة`;
    document.querySelector('meta[name="title"]').setAttribute("content","محضر اجتماع الحوكمة");
    document.querySelector('meta[name="description"]').setAttribute("content", "موقف الحوكمة داخل جامعة النسور");
    document.querySelector('meta[property="og:title"]').setAttribute("content","محضر اجتماع الحوكمة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "موقف الحوكمة داخل جامعة النسور");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${Url}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}
</style>
