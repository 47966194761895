<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="ConditionsForTransferringBetweenUniversities" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <b-container>
          <h4>{{ $t("ConditionsForTransferringBetweenUniversities") }}</h4>
          <ol class="mb-5">
            <li>
              {{ $t("transition.terms.li1") }}
            </li>
            <li>
              {{ $t("transition.terms.li2") }}
            </li>
            <li>{{ $t("transition.terms.li3") }}</li>
            <li>{{ $t("transition.terms.li4") }}</li>
          </ol>
          <ul class="m-0">
            <li class="bold">
              {{ $t("transition.morning.title") }}
            </li>
            <p class="mb-5">
              {{ $t("transition.morning.des") }}
            </p>
            <li class="bold">
              {{ $t("transition.private.title") }}
            </li>
            <p>
              {{ $t("transition.private.des1") }}
            </p>
            <p>
              {{ $t("transition.private.des2") }}
            </p>
            <p>
              {{ $t("transition.private.des3") }}
            </p>
            <li class="bold">{{ $t("transition.public.title") }}</li>
            <p>
              {{ $t("transition.public.des") }}
            </p>
          </ul>
        </b-container>
      </section>
      <section class="sec">
        <b-container>
          <a class="links-button"
            :href="'/application/steps'" 
            target="_blank"
          >
            <button-fill text="RegistrationMechanism" />
          </a>
          <a class="links-button"
           :href="'/application/terms'" 
            target="_blank"
          >
            <button-fill text="AdmissionRequirements" />
          </a>
          <a class="links-button"
            :href="`/application/admission`"
            target="_blank"
          >
            <button-fill text="admissionPlan" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Conditions for transferring between universities`;
    document.querySelector('meta[name="title"]').setAttribute("content","Conditions for transferring between universities");
    document.querySelector('meta[name="description"]').setAttribute("content", "The student has the right to transfer from his university to the corresponding study at another university if the original university approves.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Conditions for transferring between universities");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The student has the right to transfer from his university to the corresponding study at another university if the original university approves.");
    }else{
    document.title = `جامعة النسور  - شروط الانتقال بين الجامعات`;
    document.querySelector('meta[name="title"]').setAttribute("content","شروط الانتقال بين الجامعات");
    document.querySelector('meta[name="description"]').setAttribute("content", "يحق للطالب الانتقال من جامعته إلى الدراسة المناظرة في جامعة أخرى في حال موافقة الجامعة الأصلية");
    document.querySelector('meta[property="og:title"]').setAttribute("content","شروط الانتقال بين الجامعات");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "يحق للطالب الانتقال من جامعته إلى الدراسة المناظرة في جامعة أخرى في حال موافقة الجامعة الأصلية");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
