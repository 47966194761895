<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="tech" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <section class="section-details__manage">
          <b-container>
            <div>
              <p>{{ $t("techInfo") }}</p>
            </div>
          </b-container>
        </section>
        <section class="section-details__missions">
          <b-container>
            <h4>{{ $t("techTasks.des") }}</h4>
            <ol>
              <b-row>
                <b-col sm="12" md="6">
                  <div class="pl-md-5">
                    <li>{{ $t("techTasks.li1") }}</li>
                    <li>
                      {{ $t("techTasks.li2") }}
                    </li>
                    <li>
                      {{ $t("techTasks.li3") }}
                    </li>
                    <li>{{ $t("techTasks.li4") }}</li>
                    <li>{{ $t("techTasks.li5") }}</li>
                  </div>
                </b-col>
                <b-col sm="12" md="6">
                  <div class="pr-md-5">
                    <li>
                      {{ $t("techTasks.li6") }}
                    </li>
                    <li>{{ $t("techTasks.li7") }}</li>
                    <li>
                      {{ $t("techTasks.li8") }}
                    </li>
                    <li>
                      {{ $t("techTasks.li9") }}
                    </li>
                  </div>
                </b-col>
              </b-row>
            </ol>
          </b-container>
        </section>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Media & Information Technology`;
    document.querySelector('meta[name="title"]').setAttribute("content","Media & Information Technology");
    document.querySelector('meta[name="description"]').setAttribute("content", "The Information Technology Division is the leading division in the field of informatics and technology and provides technological and technical support to all parts of the educational institution by harnessing information technology and its tools to supply all departments of the college and provide them with information solutions.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Media & Information Technology");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The Information Technology Division is the leading division in the field of informatics and technology and provides technological and technical support to all parts of the educational institution by harnessing information technology and its tools to supply all departments of the college and provide them with information solutions.");
    }else{
    document.title = `جامعة النسور  - الاعلام و تكنولوجيا المعلومات`;
    document.querySelector('meta[name="title"]').setAttribute("content","الاعلام و تكنولوجيا المعلومات");
    document.querySelector('meta[name="description"]').setAttribute("content", "شعبة تكنولوجيا المعلومات هي الشعبة الرائدة في مجال المعلوماتية و التقنية و توفير الدعم التكنولوجي و التقني لكافة اجزاء المؤسسة التعليمية و ذلك  بتسخير تكنولوجيا المعلومات و ادواتها لرفد كافة اقسام الكلية و تزويدها بحلول معلوماتية");
    document.querySelector('meta[property="og:title"]').setAttribute("content","الاعلام و تكنولوجيا المعلومات");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "شعبة تكنولوجيا المعلومات هي الشعبة الرائدة في مجال المعلوماتية و التقنية و توفير الدعم التكنولوجي و التقني لكافة اجزاء المؤسسة التعليمية و ذلك  بتسخير تكنولوجيا المعلومات و ادواتها لرفد كافة اقسام الكلية و تزويدها بحلول معلوماتية");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
