<template>
  <div class="management organazation">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="Collegeadministration" />
    </header>
    <main>
      <section class="section-management" data-aos="fade-up">
        <b-container>
          <b-row>
            <b-col>
              <h3>
              {{ $t("colMang.col1") }}
              </h3>
              <ol>
                <li>{{ $t("colMang.col2") }}ل</li>
                <li>
                  {{ $t("colMang.col3") }}
                </li>
                <li>
                  {{ $t("colMang.col4") }}
                </li>
              </ol>
              <h4>{{ $t("colMang.col5") }}</h4>
              <ul>
                <li>{{ $t("colMang.col6") }}</li>
                <li>
                    {{ $t("colMang.col7") }}
                </li>
                <li>
                    {{ $t("colMang.col8") }}
                </li>
                <li>{{ $t("colMang.col9") }}</li>
                <li>{{ $t("colMang.col10") }}</li>
                <li>
                  {{ $t("colMang.col11") }}
                </li>
                <li>
                  {{ $t("colMang.col12") }}
                </li>
                <li>
                  {{ $t("colMang.col13") }}
                </li>
                <li>
                  {{ $t("colMang.col14") }}
                </li>
                <li>{{ $t("colMang.col15") }}</li>
                <li>
                  {{ $t("colMang.col16") }}
                </li>
                <li>
                  {{ $t("colMang.col17") }}
                </li>
                <li>
                  {{ $t("colMang.col18") }}
                </li>
                <li>{{ $t("colMang.col19") }}</li>
                <li>
                  {{ $t("colMang.col20") }}
                </li>
                <li>
                  {{ $t("colMang.col21") }}
                </li>
                <li>
                  {{ $t("colMang.col22") }}
                </li>
                <li>
                  {{ $t("colMang.col23") }}
                </li>
                <li>
                  {{ $t("colMang.col24") }}
                </li>
                <li>
                  {{ $t("colMang.col25") }}
                </li>
                <li>
                    {{ $t("colMang.col26") }}
                </li>
                <li>{{ $t("colMang.col27") }}</li>
              </ul>
              <!-- <h3>رؤساء الاقسام العلمية</h3>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>الاسم الثلاثي</th>
                      <th>اللقب العلمي</th>
                      <th>المنصب الحالي</th>
                      <th>السيرة الذاتية</th>
                    </tr>
                  </thead>
                </table>
              </div> -->
              <!-- <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>د. زهير إبراهيم عبد الجبار</td>
                      <td>استاذ</td>
                      <td>رئيس قسم تقنيات التحليلات المرضية</td>
                      <td>
                        <a href="#"><i class="fas fa-download"></i>تحميل</a>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>د. علاء الدين محمود كريم</td>
                      <td>أستاذ مساعد</td>
                      <td>رئيس قسم ادارة الاعمال</td>
                      <td>
                        <a href="#"><i class="fas fa-download"></i>تحميل</a>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>د. مكي مهدي عبد الحسن</td>
                      <td>أستاذ مساعد</td>
                      <td>رئيس قسم الهندسة</td>
                      <td>
                        <a href="#"><i class="fas fa-download"></i>تحميل</a>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>د. نظام شيت حميد</td>
                      <td>أستاذ</td>
                      <td>رئيس قسم اللغة الإنكليزية</td>
                      <td>
                        <a href="#"><i class="fas fa-download"></i>تحميل</a>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>د. خالد سمان جواد</td>
                      <td>أستاذ مساعد</td>
                      <td>رئيس قسم القانون</td>
                      <td>
                        <a href="#"><i class="fas fa-download"></i>تحميل</a>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>د. بسام عباس البياتي</td>
                      <td>استاذ</td>
                      <td>رئيس قسم التربية البدنية وعلوم الرياضة</td>
                      <td>
                        <a href="#"><i class="fas fa-download"></i>تحميل</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="section-deps">
        <b-container>
          <section class="section section-links">
            <h3>{{ $t("Additionallinks") }}</h3>
            <ul class="links">
              <li>
                <router-link
                  :to="{ name: 'agreements'}"
                >
                  {{ $t("agreements") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'organazation'}"
                >
                  {{ $t("organazationChart") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'fundation'}"
                >
                  {{ $t("foundation") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'calender'}"
                >
                  {{ $t("aboutUs.about17") }}
                </router-link>
              </li>
            </ul>
          </section>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - College administration`;
    document.querySelector('meta[name="title"]').setAttribute("content","College administration");
    document.querySelector('meta[name="description"]').setAttribute("content", "Al-Nisour University  is one of the most prestigious private colleges in Iraq. It was opened in 2011 by a decision of the Council of Ministers. The college specializes in the fields of pharmacy, anaesthesia techniques, radiology and ultrasound techniques, computer techniques engineering, pathological analysis techniques, law, business administration, English language, Physical education and sports sciences, and to be of benefit and distinction in higher education and scientific research leading to the development of knowledge, community service, graduating efficient staff, and working to expand horizontally and vertically in specialization");
    document.querySelector('meta[property="og:title"]').setAttribute("content","College administration");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "Al-Nisour University  is one of the most prestigious private colleges in Iraq. It was opened in 2011 by a decision of the Council of Ministers. The college specializes in the fields of pharmacy, anaesthesia techniques, radiology and ultrasound techniques, computer techniques engineering, pathological analysis techniques, law, business administration, English language, Physical education and sports sciences, and to be of benefit and distinction in higher education and scientific research leading to the development of knowledge, community service, graduating efficient staff, and working to expand horizontally and vertically in specialization");
    }else{
    document.title = `جامعة النسور  - ادارة الكلية`;
    document.querySelector('meta[name="title"]').setAttribute("content","ادارة الكلية");
    document.querySelector('meta[name="description"]').setAttribute("content", "اعضاء مجلس جامعة النسور وهو اعلى هيئة علمية وادارية في الكلية وهو أعلى هيئة علمية وادارية فيها");
    document.querySelector('meta[property="og:title"]').setAttribute("content","ادارة الكلية");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "اعضاء مجلس جامعة النسور وهو اعلى هيئة علمية وادارية في الكلية وهو أعلى هيئة علمية وادارية فيها");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
