<template>
  <div class="blog-post blog">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="Latestnews" />
    </header>
    <main>
      <b-container class="mt-5" fluid>
        <b-row>
          <!-- <h1>Current ID: {{ params }}</h1> -->

          <b-col sm="12" md="9" class="p-0">
            <b-row class="px-md-5 align-items-stretch" v-if="posts.length !== 0">
              <b-col sm="12" md="3" class="mb-5" v-for="(post, index) in posts" :key="post.id">
                <div class="blog-card" data-aos="fade" :data-aos-delay="`${index + 5}00`">
                  <div class="image-placeholder">
                    <img :src="getImage(post.image)" alt="" />
                  </div>
                  <div class="content">
                    <h3>
                      <router-link :to="{ name: 'blog-post', params: { id: post.id } }">{{ shortenTitle(post.title) }}
                      </router-link>
                    </h3>
                    <p>
                      {{ shortenText(post.sub_description) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="3" class="p-0 pl-md-5">
            <blog-sidebar :posts="posts" :popularPosts="popularPosts" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="9" class="pt-5">
            <b-container>
              <b-row>
                <b-col class="d-flex justify-content-center mb-5">
                  <b-pagination size="lg" v-model="currentPage" :total-rows="totalCount" :per-page="perPage"
                    aria-controls="my-table" class="mx-auto"></b-pagination>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col sm="12" md="3"></b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import BlogSidebar from "@/components/blog/BlogSidebar.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    BlogSidebar,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      perPage: 16,
      currentPage: 1,
      posts: [],
      popularPosts: [],
      isMobile: false,
      lang: null,
      totalCount: "",
      params: this.$route.params.params
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getAllPosts();
      },
    },
    '$route.params.params': {
      handler: function (newId) {
        this.params = newId;
        this.getAllPosts(); // Refetch posts for the new ID
        console.log('Route parameter changed to:', newId);
      },
      immediate: true // This ensures the handler is called on initialization
    }
  },
  computed: {
    rows() {
      return this.posts.length;
    },
  },
  async mounted() {
    console.log('Component mounted with ID:', this.id);

    if (localStorage.getItem("lang") == "en") {
      document.title = ` Al-Nisour University   - Latest News`;
      document.querySelector('meta[name="title"]').setAttribute("content", "Latest News");
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.querySelector('meta[property="og:title"]').setAttribute("content", "Latest News");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    } else {
      document.title = `جامعة النسور  - الاخبار و النشاطات`;
      document.querySelector('meta[name="title"]').setAttribute("content", "الاخبار و النشاطات");
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.querySelector('meta[property="og:title"]').setAttribute("content", "الاخبار و النشاطات");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getAllPosts();
    this.getPopularPosts();
  },
  methods: {
    getAllPosts() {
      console.log("here")
      let skip = this.currentPage - 1;
      let is_ai = this.$route.params.params || null; // Get the is_ai parameter
      let url =
        `news/clint?skip=${skip}&take=${this.perPage
        }&filter[${localStorage.getItem("lang")}_title]=${this.$route.params.search == undefined
          ? ""
          : this.$route.params.search
        }department_id=null`
        if (is_ai != null) {
        url += `&is_ai=${is_ai}`;
      }
      this.axios
        .get(url)
        .then((res) => {
          this.posts = res.data.items;
          this.totalCount = res.data.totalCount;
        });
    },
    getPopularPosts() {
      this.axios.get("news/list/viewSort?skip=0&take=3").then((res) => {
        this.popularPosts = res.data.items;
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenTitle(text) {
      if (text !== null) {
        if (text.length > 50) {
          let maxLength = 50;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString + " ...";
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
    shortenText(text) {
      if (text !== null) {
        if (text.length > 100) {
          let maxLength = 100;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString + " ...";
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
  },
};
</script>
