<template>
  <div>
    <!-- desktop nav -->
    <b-navbar toggleable="lg" id="desktop-nav">
      <b-navbar-brand href="#">
         <img v-if="language == 'EN'" src='../../assets/images/en-logo.png' />
        <img v-else src='../../assets/images/logo.png' alt="" />
      </b-navbar-brand>
      <!-- <h1 class="ml-5 mr-3 site-name">{{ $t("alnisour") }}</h1> -->

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#">
            <router-link :to="{ name: 'Home' }">
              <ButtonLink text="Home" />
            </router-link>
          </b-nav-item>

          <b-nav-item href="#">
            <ButtonLink text="SubmissionAndAcceptance" />
            <ul class="main-dropdown">
              <li>
                <router-link :to="{ name: 'application-steps' }">{{
                  $t("RegistrationMechanism")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'application-terms' }">{{
                  $t("AdmissionRequirements")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'application-transfer' }">{{
                  $t("ConditionsForTransferringBetweenUniversities")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'admission-plan' }">
                  {{ $t("admissionPlan") }}
                </router-link>
              </li>
            </ul>
          </b-nav-item>
          <!-- management deps -->
          <b-nav-item href="#">
            <ButtonLink text="managmentDeps" />
            <ul class="main-dropdown">
              <li>
                <router-link :to="{ name: 'council' }">{{
                  $t("council")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'tech' }">{{
                  $t("tech")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'quality' }">{{
                  $t("quality")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'learning' }">{{
                  $t("learning")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'reg' }">{{ $t("reg") }}</router-link>
              </li>

              <li>
                <router-link :to="{ name: 'planing' }">{{
                  $t("planing")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'energyManagementSystem' }">{{
                  $t("energy management system")
                }}</router-link>
              </li>
            </ul>
          </b-nav-item>
          <!-- end of management deps -->
          <b-nav-item href="https://academy.uon.edu.iq/"  target="_blank">  
               <ButtonLink text="colleges" />
           </b-nav-item>
          <b-nav-item href="#"> 
            <router-link :to="{ name: 'blog' }">
              <ButtonLink text="NewsandEvents" />
            </router-link>
          </b-nav-item>
          <!-- <b-nav-item href="#">
            <router-link :to="{ name: 'contact-us' }">
              <ButtonLink text="ContactUs" />
            </router-link>
          </b-nav-item> -->
          <b-nav-item href="#">
            <ButtonLink text="researches" />
            <ul class="main-dropdown">
              <li>
                <router-link :to="{ name: 'collage-researches' }">{{
                  $t("collage-searches")
                }}</router-link>
              </li>
              <li>
                <a @click="toLink('https://portal.nuc.edu.iq/auth')" href="https://portal.nuc.edu.iq/auth"
                  target="_blank">{{ $t("Nisourportal") }}</a>
              </li>
              <li>
                <a @click="toLink('https://nuc.bis.edu.iq')" href="https://nuc.bis.edu.iq" target="_blank">{{
                  $t("bolognaprocess") }}</a>
              </li>
              <li>
                <a @click="toLink('https://journal.nuc.edu.iq/')" href="https://journal.nuc.edu.iq/" target="_blank">{{
                  $t("magazin") }}</a>
              </li>
              <li>
                <a @click="toLink('https://classroom.google.com')" href="https://classroom.google.com"
                  target="_blank">{{ $t("classroom") }}</a>
              </li>
              <li>
                <a @click="
                  toLink(
                    'https://scholar.google.com/citations?view_op=search_authors&hl=ar&mauthors=nuc.edu.iq&before_author=_JZl_wIAAAAJ&astart=0'
                  )
                  "
                  href="https://scholar.google.com/citations?view_op=search_authors&hl=ar&mauthors=nuc.edu.iq&before_author=_JZl_wIAAAAJ&astart=0"
                  target="_blank">{{ $t("scholar") }}</a>
              </li>
              <li>
                <a @click="
                  toLink(
                    'https://www.researchgate.net/institution/Al-Nisour_University_College'
                  )
                  " href="https://www.researchgate.net/institution/Al-Nisour_University_College" target="_blank">{{
                    $t("researchGate") }}</a>
              </li>
              <li>
                <a @click="toLink('https://nuc-iq.academia.edu')" href="https://nuc-iq.academia.edu" target="_blank">{{
                  $t("academia") }}</a>
              </li>
            </ul>
          </b-nav-item>
          <b-nav-item href="#">
            <ButtonLink text="studentsAndEducations" />
            <ul class="main-dropdown">
              <li>
                <a @click="toLink('#')" target="_blank">{{
                  $t("students.sis")
                }}</a>
              </li>
              <li>
                <a @click="toLink('https://nuc.bis.edu.iq/student/login?to=/student')" target="_blank">{{
                  $t("students.Bologna system")
                }}</a>
              </li>
              <li>
                <a @click="toLink('https://classroom.google.com/')" target="_blank">{{ $t("students.classroom") }}</a>
              </li>
              <li>
                <a @click="toLink('http://lms.nuc.edu.iq/login/index.php')" target="_blank">{{ $t("students.moodle")
                  }}</a>
              </li>
              <li>
                <a @click="
                  toLink(
                    'https://new.edmodo.com/school/AlNisourUniveristyCollegeiraq991646'
                  )
                  " target="_blank">{{ $t("students.edmodo") }}</a>
              </li>
              <li>
                <a @click="
                  toLink(
                    'https://forms.gle/ferUpBPxiXWAGa999'
                  )
                  " target="_blank">{{ $t("Opinion statement about graduates") }}</a>
              </li>
              <li>
                <router-link :to="{ name: 'Alumni' }">
                  {{ $t("students.graduate") }}
                </router-link>
              </li>
            </ul>
          </b-nav-item>
          <b-nav-item href="#">
            <router-link :to="{ name: 'installment' }">
              <ButtonLink text="fees" />
            </router-link>
          </b-nav-item>
          <b-nav-item href="#">
            <ButtonLink text="Abouttheuniversity" />
            <ul class="main-dropdown">
              <li>
                <router-link :to="{ name: 'agreements' }">
                  {{ $t("agreements") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'fundation' }">{{
                  $t("foundation")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'management' }">
                  {{ $t("Collegeadministration") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'organazation' }">
                  {{ $t("organazationChart") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'calender' }">
                  {{ $t("aboutUs.about17") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Statics' }">
                  {{ $t("College statistics") }}
                </router-link>
              </li>
            </ul>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import ButtonLink from "./ButtonLink.component.vue";
export default {
  components: {
    ButtonLink,
  },
  data() {
    return {
      deps: null,
      language: "AR",
    };
  },
  mounted() {
    let mobileNav = document.getElementById("mobile-nav");
    let desktopNav = document.getElementById("desktop-nav");
    this.getDeps();
    if (window.innerWidth >= 992) {
      if (mobileNav) {
        mobileNav.style.display = "none";
        desktopNav.style.display = "block";
      }
    } else {
      if (mobileNav) {
        mobileNav.style.display = "block";
        desktopNav.style.display = "none";
      }

    }
    if (localStorage.getItem("lang"))
      this.language = localStorage.getItem("lang").toUpperCase();
    else {
      localStorage.setItem("lang", "ar");
      this.language = localStorage.getItem("lang").toUpperCase();
    }
  },
  methods: {
    // goToDep(name) {
    //   this.$store.dispatch("getDepName", name);
    //   this.$router.push({ name: "about-dep", params: { name: name } });
    // },
    toLink(link) {
      window.open(link, "_blank");
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    getDeps() {
      this.axios.get("since/deparment/clint?skip=0&take=1000&type=5").then((res) => {
        this.deps = res.data.items;
      });
    },
    toAboutId(id) {
      if (this.$route.params.id == id) {
        this.$store.dispatch("getDepId", id);
        this.$router.push({ name: "about-dep", params: { id: id } });
      } else {
        this.$store.dispatch("getDepId", id);
        this.$router.push({ name: "about-dep", params: { id: id } });
      }
    },
    toStaffId(id) {
      if (this.$route.params.id == id) {
        this.$store.dispatch("getDepId", id);
        this.$router.push({ name: "staff", params: { id: id } });
      } else {
        this.$store.dispatch("getDepId", id);
        this.$router.push({ name: "staff", params: { id: id } });
      }
    },
    toTable(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "table",
          params: { id: dep.id, depName: dep.name },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "table",
          params: { id: dep.id, depName: dep.name },
        });
      }
    },
    toLectures(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "lectures",
          params: { id: dep.id, depName: dep.name },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "lectures",
          params: { id: dep.id, depName: dep.name },
        });
      }
    },
    toResearches(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-research",
          params: { id: dep.id },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-research",
          params: { id: dep.id },
        });
      }
    },
    toNews(id) {
      if (this.$route.params.id == id) {
        this.$store.dispatch("getDepId", id);
        this.$router.push({
          name: "dep-news",
          params: { id: id },
        });
      } else {
        this.$store.dispatch("getDepId", id);
        this.$router.push({
          name: "dep-news",
          params: { id: id },
        });
      }
    },
    toDepResearches(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "dep-researches",
          params: { id: dep.id },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "dep-researches",
          params: { id: dep.id },
        });
      }
    },

    tograduated(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-graduated",
          params: { id: dep.id },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-graduated",
          params: { id: dep.id },
        });
      }
    },
  },
};
</script>
