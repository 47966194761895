<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="AdmissionRequirements" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <b-container>
          <h4>
            {{ $t("terms.title") }}
          </h4>
          <ul>
            <li class="bold li-header">{{ $t("terms.morning.title") }}</li>
            <ol class="mb-10">
              <li>
                {{ $t("terms.morning.li1") }}
              </li>
              <li>{{ $t("terms.morning.li2") }}</li>
              <li>
                {{ $t("terms.morning.li3") }}
              </li>
              <li>{{ $t("terms.morning.li4") }}</li>
              <li>
                {{ $t("terms.morning.li5") }}
              </li>
            </ol>

            <li class="bold li-header">{{ $t("terms.evening.title") }}</li>
            <ol class="mb-10">
              <li>
                {{ $t("terms.evening.li1") }}
              </li>
              <li>{{ $t("terms.evening.li2") }}</li>
              <li>
                {{ $t("terms.evening.li3") }}
              </li>
            </ol>

            <li class="bold li-header">{{ $t("terms.incoming.title") }}</li>
            <ol>
              <li>
                {{ $t("terms.incoming.li1") }}
              </li>
              <li>
                {{ $t("terms.incoming.li2") }}
              </li>
              <li>
                {{ $t("terms.incoming.li3") }}
              </li>
              <li>
                {{ $t("terms.incoming.li4") }}
              </li>
            </ol>
          </ul>
        </b-container>
      </section>
      <section class="sec">
        <b-container>
          <a class="links-button"
            :href="'/application/steps'" 
            target="_blank"
          >
            <button-fill text="RegistrationMechanism" />
          </a>
          <a class="links-button"
            :href="`/application/admission`"
            target="_blank"
          >
            <button-fill text="admissionPlan" />
          </a>
          <a class="links-button"
            :href="`/application/transfer`"
            target="_blank"
          >
            <button-fill text="ConditionsForTransferringBetweenUniversities" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Admisson requirements`;
    document.querySelector('meta[name="title"]').setAttribute("content","Admisson requirements");
    document.querySelector('meta[name="description"]').setAttribute("content", "The student must have an Iraqi Preparatory Certificate or its equivalent, supported by attestation by the Ministry of Education.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Admisson requirements");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The student must have an Iraqi Preparatory Certificate or its equivalent, supported by attestation by the Ministry of Education.");
    }else{
    document.title = `جامعة النسور  - شروط القبول`;
    document.querySelector('meta[name="title"]').setAttribute("content","شروط القبول");
    document.querySelector('meta[name="description"]').setAttribute("content", "الشروط العامة للقبول في الدراستين الصباحية والمسائية للعام الدراسي 2021/2020");
    document.querySelector('meta[property="og:title"]').setAttribute("content","شروط القبول");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "الشروط العامة للقبول في الدراستين الصباحية والمسائية للعام الدراسي 2021/2020");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
