<template>
  <div>
    <b-container fluid class="p-0">
      <div class="topbar">
        <!-- <b-container> -->
        <b-row>
          <b-col cols="4">
            <div class="d-flex align-items-center h-100">
              <div class="topbar__content d-flex">
                <div class="topbar__content--icon">
                  <i class="fas fa-envelope"></i>
                </div>
                <div class="topbar__content--text">
                  <a href="mailto:info@nuc.edu.iq">info@nuc.edu.iq</a>
                </div>
              </div>
              <span class="mx-3">|</span>
              <div class="topbar__content d-flex">
                <div class="topbar__content--icon">
                  <i class="fas fa-phone"></i>
                </div>
                <div class="topbar__content--text" dir="ltr">6770</div>
              </div>
            </div>
          </b-col>
          <b-col cols="8">
            <ul class="d-flex justify-content-end align-items-center m-0">
              <!-- <li>
                <a :href="'https://scholarship.nuc.edu.iq/'
                  " target="_blank">{{ $t("foreigners scholarship") }}</a>
              </li> -->
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <router-link :to="{ name: 'gallery' }" class="p-3">{{
                  $t("gallery")
                }}</router-link>
              </li>
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <router-link :to="{ name: 'courses' }" class="p-3">{{
                  $t("ContinuingEducationCenter")
                }}</router-link>
              </li>
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <router-link :to="{ name: 'jobs' }" class="p-3">{{
                  $t("Availablejobs")
                }}</router-link>
              </li>
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <router-link :to="{ name: 'job-application' }" class="p-3">
                  {{ $t("JobApplication") }}
                </router-link>
              </li>
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <router-link :to="`/faq`" class="p-3">
                  {{ $t("faq") }}
                </router-link>
              </li>
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <router-link :to="`/blog/${true}`" class="p-3">
                  {{ $t("activitiesAi") }}
                </router-link>
              </li>
              
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <router-link :to="`/contact-us`" class="p-3">
                  {{ $t("ContactUs") }}
                </router-link>
              </li>

              <li>
                <span class="mx-3">|</span>
              </li>
              <li v-if="isLoggedIn == false">
                <router-link :to="{ name: 'login' }" class="p-3">
                  {{ $t("Login") }}
                </router-link>
              </li>
              <li v-if="isLoggedIn == true">
                <b-nav-item-dropdown :text="$t('Profile')" right>
                  <b-dropdown-item :href="`/profile`">
                    {{ $t("ProfileInfo") }}
                    <!-- <router-link :to="{ name: 'profile' }" class="p-3">
                      {{ $t("Profile") }}
                    </router-link> -->
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="logout">
                    {{ $t("Logout") }}
                    <!-- <router-link :to="{ name: 'logout' }" class="p-3">
                      {{ $t("Logout") }}
                    </router-link> -->
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </li>
              <li>
                <span class="mx-3">|</span>
              </li>
              <li>
                <b-nav-item-dropdown :text="language" right>
                  <b-dropdown-item @click="changeLang('ar')">AR</b-dropdown-item>
                  <b-dropdown-item @click="changeLang('en')">EN</b-dropdown-item>
                </b-nav-item-dropdown>
              </li>
            </ul>
          </b-col>
        </b-row>
        <!-- </b-container> -->
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false,
      language: "AR",
    };
  },
  mounted() {
    let topbar = document.querySelector(".topbar");
    if (localStorage.getItem("NisourToken")) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

    if (window.innerWidth <= 992) {
      topbar.style.display = "none";
    }
    if (localStorage.getItem("lang"))
      this.language = localStorage.getItem("lang").toUpperCase();
    else {
      localStorage.setItem("lang", "ar");
      this.language = localStorage.getItem("lang").toUpperCase();
    }
  },
  methods: {
    logout() {
      // this.axios.get("me/logout").then(() => {
      console.log(localStorage.removeItem("NisourToken"))
      localStorage.removeItem("NisourToken");
      this.$swal("تم تسجيل الخروج بنجاح", "", "success");
      window.location.reload();
      // });
    },
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
      this.language = localStorage.getItem("lang").toUpperCase();
    },
  },
};
</script>
