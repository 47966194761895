<template>
  <div class="dep-info research">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="students.alumni" />
    </header>
    <main>
      <section class="tracking-body">
          <div class="container-center">
            <div class="tracking-headertext">{{ $t("students.alumni") }}</div>
          </div>
          <div class="container-center">
            <div class="custom-input">
            <input type="text" v-model="title" placeholder="ابحث عن الخريج ....">
          </div>
          </div>
        </section>
      <section class="section-deps">
        <b-container>
          <section data-aos="fade-left" class="section section-lessons">
            <h3>
              {{ $t("students.alumni") }}
            </h3>
            <div>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t("studentResearch.name") }}</th>
                      <th>{{ $t("studentResearch.year") }}</th>
                      <th>{{ $t("studentResearch.dep") }}</th>
                      <th>{{ $t("studentResearch.title") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr
                      v-for="(research, index) in installments"
                      :key="research.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ research.research_title }}</td>
                      <td>{{ research.year }}</td>
                      <td>{{ research.depatrment.name }}</td>
                      <td>{{ research.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </b-container>
      </section>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";

export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      installments: null,
      totalInstallment: 0,
      isMobile: false,
      perPage: 10,
      currentPage: 1,
      totalCount: "",
      title:''
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getResearches();
      },
    },
    title: {
      handler: function () {
        this.getResearches();
      },
    },
  },
  async mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Graduate Students`;
    document.querySelector('meta[name="title"]').setAttribute("content","Graduate Students");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Graduate Students");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `جامعة النسور  - الطلبة الخريجين`;
    document.querySelector('meta[name="title"]').setAttribute("content","الطلبة الخريجين");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","الطلبة الخريجين");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getResearches();
  },
  methods: {
    getResearches() {

      let skip = this.currentPage - 1;
            this.axios.get(`students/researches/clint?skip=${skip}&take=${this.perPage}&title=${this.title}`).then((res) => {
              this.installments = res.data.items;
          this.totalCount = res.data.totalCount;
      });
    },
  },
};
</script>
