<template>
  <div class="application fundation organazation">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="quality" />
    </header>
    <main>
      <section class="py-10 section-message">
          <b-container>
            <b-row>
              <b-col
                sm="12"
                md="12"
                class="d-flex align-items-center"
                data-aos="fade-left"
              >
                <div class="text">
                  <h3>{{ $t("qualityTasks.li14") }}</h3>
                  <p>
                    <span>{{ $t("qualityTasks.li15") }}</span>
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
      <section class="section-details" data-aos="fade-up">
        
        <section class="section-details__missions">
          <b-container>
            <h4>{{ $t("qualityTasks.des") }}</h4>
            <ol>
              <b-row>
                <b-col sm="12" md="6">
                  <div class="pl-md-5">
                    <li>{{ $t("qualityTasks.li1") }}</li>
                    <li>
                      {{ $t("qualityTasks.li2") }}
                    </li>
                    <li>
                      {{ $t("qualityTasks.li3") }}
                    </li>
                    <li>{{ $t("qualityTasks.li4") }}</li>
                    <li>{{ $t("qualityTasks.li5") }}</li>
                    <li>
                      {{ $t("qualityTasks.li6") }}
                    </li>
                    <li>{{ $t("qualityTasks.li7") }}</li>
                  </div>
                </b-col>
                <b-col sm="12" md="6">
                  <div class="pr-md-5">
                    <li>
                      {{ $t("qualityTasks.li8") }}
                    </li>
                    <li>{{ $t("qualityTasks.li9") }}</li>
                    <li>
                      {{ $t("qualityTasks.li10") }}
                    </li>
                    <li>
                      {{ $t("qualityTasks.li11") }}
                    </li>
                    <li>
                      {{ $t("qualityTasks.li12") }}
                    </li>
                    <li>
                      {{ $t("qualityTasks.li13") }}
                    </li>
                  </div>
                </b-col>
              </b-row>
            </ol>
          </b-container>
        </section>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University   - Quality Assurance Department`;
    document.querySelector('meta[name="title"]').setAttribute("content","Quality Assurance Department");
    document.querySelector('meta[name="description"]').setAttribute("content", "Spreading the culture of quality assurance and academic accreditation in the college and its affiliated institutions.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Quality Assurance Department");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "Spreading the culture of quality assurance and academic accreditation in the college and its affiliated institutions.");
    }else{
    document.title = `جامعة النسور  - قسم ضمان الجودة`;
    document.querySelector('meta[name="title"]').setAttribute("content","قسم ضمان الجودة");
    document.querySelector('meta[name="description"]').setAttribute("content", "نشر ثقافة ضمان الجودة و الاعتماد الاكاديمي في الكلية و المؤسسات التابعة لها");
    document.querySelector('meta[property="og:title"]').setAttribute("content","قسم ضمان الجودة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "نشر ثقافة ضمان الجودة و الاعتماد الاكاديمي في الكلية و المؤسسات التابعة لها");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
